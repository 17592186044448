import { Tween } from "gsap/gsap-core"

export default {
	init: (gsap, ScrollTrigger, SplitText) => {

		const copyButton = document.querySelector('.tools-copy')

		const durationInput = document.querySelector('#duration')
		const easeSelect = document.querySelector('#ease')
		const adjustSelect = document.querySelector('#adjust')
		const startInput = document.querySelector('#start')
		const scrubInput = document.querySelector('#scrub')
		const scrubValueInput = document.querySelector('#scrubValue')
		const endInput = document.querySelector('#end')
		const staggerInput = document.querySelector('#stagger')

		const toolAdjust = document.querySelector('.tools-item--adjust')
		const toolScrub = document.querySelector('.tools-item--scrub')
		const toolEnd = document.querySelector('.tools-item--end')

		let tl

		let tweens = []

		const triggers = document.querySelectorAll('.trigger')

		document.addEventListener('DOMContentLoaded', createTl)

		new SplitText('.title', {
			type: 'words, lines',
			tag: 'div',
			linesClass: 'title-line',
			wordsClass: 'title-item',
			charsClass: 'title-item'
		})

		document.querySelectorAll('.tools-item').forEach(item => {
			let input = item.querySelector('input, select'),
				output = item.querySelector('output')

			input.addEventListener('input', () => {
				copyButton.toggleAttribute('data-done', false)

				ScrollTrigger.refresh()

				refreshTl()

				if (output) {
					output.value = input.value
				}
			})
		})

		function refreshTl() {
			document.querySelectorAll('.box, .content-title, .content-text, .content-image, .title, .title-item').forEach(box => {
				box.removeAttribute('style')
			})

			tweens.forEach(tween => {
				tween.kill()
			})

			tweens = []

			window.scrollTo(0, 0)

			createTl()
		}

		function createTl() {
			let scrub = scrubInput.checked

			toolAdjust.toggleAttribute('data-hidden', easeSelect.value == 'none')
			toolScrub.toggleAttribute('data-hidden', !scrub)
			toolEnd.toggleAttribute('data-hidden', !scrub)

			updateURL({
				duration: durationInput.value,
				ease: easeSelect.value,
				adjust: adjustSelect.value,
				start: startInput.value,
				scrub: scrub,
				scrubValue: scrubValueInput.value,
				end: endInput.value,
				stagger: staggerInput.value,
				closed: tools.hasAttribute('data-close')
			})

			triggers.forEach(trigger => {
				const scrollTrigger = {
					toggleActions: 'play resume resume reset',
					markers: {
						startColor: '#424242',
						endColor: scrub ? '#424242' : 'transparent',
						indent: 0
					},
					trigger: trigger,
					scrub: scrub ? parseInt(scrubValueInput.value) : false,
					start: 'top '+ startInput.value +'%',
					end: 'top '+ endInput.value +'%',
				}

				let tween

				switch (trigger.getAttribute('data-type')) {
					case 'title':
						tween = gsap.from(
							trigger.querySelectorAll('.title .title-item'),
							{
								y: '100%',
								duration: durationInput.value,
								ease: easeSelect.value +'.'+ adjustSelect.value,
								stagger: staggerInput.value,
								scrollTrigger: scrollTrigger
							}
						)
						tweens.push(tween)
						break;

					case 'box':
						tween = gsap.from(
							trigger.querySelectorAll('.box'),
							{
								opacity: 0,
								y: 120,
								duration: durationInput.value,
								ease: easeSelect.value +'.'+ adjustSelect.value,
								stagger: staggerInput.value,
								scrollTrigger: scrollTrigger
							}
						)
						tweens.push(tween)
						break;

					case 'text':
						tween = gsap.from(
							trigger.querySelectorAll('.content-title, .content-text, .content-image'),
							{
								opacity: 0,
								y: 120,
								duration: durationInput.value,
								ease: easeSelect.value +'.'+ adjustSelect.value,
								stagger: staggerInput.value,
								scrollTrigger: scrollTrigger
							}
						)
						tweens.push(tween)
						break;

					default:
						console.log('default')
						break;
				}
			})

			ScrollTrigger.refresh()
		}

		function updateURL(data) {
			const url = location.protocol + '//' + location.host + location.pathname
			const query = new URLSearchParams(data)
			const paramsToRemove = []

			query.forEach((val, key) => {
				if(val == '') paramsToRemove.push(key)
			})

			paramsToRemove.forEach(param => {
				query.delete(param)
			})

			const queryString = query.toString()

			history.replaceState({}, document.title, `${url}${queryString ? `?${queryString}` : ''}`)
		}


		// copy
		copyButton.addEventListener('click', () => {
			let scrub = scrubInput.checked

			let text = "duration: "+ durationInput.value + "\n"

			let easeText = "ease: \'"+ easeSelect.value

			if (!toolAdjust.hasAttribute('data-hidden')) {
				easeText += "."+ adjustSelect.value
			}

			text += easeText + "\'\n"

			text += "start: '"+ startInput.value +"%'\n"

			let scrubText = scrub ? scrubValueInput.value : 'false'
			text += "scrub: "+ scrubText +"\n"

			if (!toolEnd.hasAttribute('data-hidden')) {
				text += "end: '"+ endInput.value +"%'\n"
			}

			text += "stagger: "+ staggerInput.value

			const textarea = document.createElement('textarea')
			textarea.value = text
			document.body.appendChild(textarea)
			textarea.select()
			document.execCommand('copy')
			document.body.removeChild(textarea)

			copyButton.toggleAttribute('data-done', true)
			setTimeout(() => {
				copyButton.toggleAttribute('data-done', false)
			}, 15000)
		})


		// tools toggle
		const tools = document.querySelector('.tools'),
			  toolsTrigger = tools.querySelector('.tools-trigger')

		toolsTrigger.addEventListener('click', e => {
			tools.toggleAttribute('data-close')

			updateURL({
				duration: durationInput.value,
				ease: easeSelect.value,
				adjust: adjustSelect.value,
				start: startInput.value,
				scrub: scrub,
				scrubValue: scrubValueInput.value,
				end: endInput.value,
				stagger: staggerInput.value,
				closed: tools.hasAttribute('data-close')
			})
		})


		// background color
		let colors = ['#F8CED3', '#F9F9DE', '#D2FAE2', '#D1BFED', '#F8CED3']

		new ScrollTrigger({
			trigger: 'main',
			start: 'top top',
			end: 'bottom 100%',
			scrub: true,
			onUpdate: (self) => {
				let colorIndex = Math.round(self.progress * (colors.length - 1))
				console.log(colorIndex)
				console.log(colors[colorIndex])

				gsap.to('html', {
					'--background': colors[colorIndex]
				})
			}
		})

    }
}