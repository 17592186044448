/*
|
| Importing Libs
|------------------
*/
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import SplitText from '@lib/gsap-pro/SplitText'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(SplitText)

/*
|
| Importing Utils
|-------------------
*/
import * as app from '@utils/global'
import Router from '@utils/router'

/*
|
| Importing App files
|----------------------
*/
import main from '@pages/main'
import globe from '@pages/globe'

/*
|
| components
|----------------------
*/
import footer from '@components/footer'

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        file: main,
        dependencies: [gsap, ScrollTrigger, SplitText],
        resolve: 'main'
    },
    {
        file: globe,
        dependencies: [gsap, ScrollTrigger, SplitText],
        resolve: '#globe'
    },
    {
        file: footer,
        dependencies: [gsap],
        resolve: 'footer'
    }
])

/*
|
| Run
|------
*/
routes.load()
