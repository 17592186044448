import { Tween } from "gsap/gsap-core"

export default {
	init: (gsap, ScrollTrigger, SplitText) => {

        document.querySelectorAll('.anim span').forEach((item) => {
            item.split = new SplitText(item, {
                type: item.dataset.splitText ? item.dataset.splitText : 'chars, words',
                charsClass: 'char'
            })
        })

        const chars = document.querySelectorAll('.char');

        gsap.fromTo(chars, {
            'will-change': 'opacity, transform',
            opacity: 0,
            rotationX: -90,
            yPercent: 50
        },
        {
            ease: 'power1.out',
            opacity: 1,
            rotationX: 0,
            yPercent: 0,
            stagger: {
                each: 0.03,
                from: 0
            },
            scrollTrigger: {
                // markers: true,
                trigger: '.anim-title',
                scrub: 1,
                start: 'top 90%',
                end: 'top 50%',
            }
        })

    }
}