export default {
	init: (gsap) => {

		const footer = document.querySelector('footer')

		footer.addEventListener('click', e => {
			window.scrollTo(0, 0)
		})

    }
}